import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/IpoPage/Banner/Banner";
import What from "../components/IpoPage/What/What";
import Why from "../components/IpoPage/Why/Why";
import Priority from "../components/IpoPage/Priority/Priority";
import Cards from "../components/IpoPage/Cards/Cards";

const IpoPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>STL Investment · IPOs</title>
        <meta name="title" content="Participate in IPOs with Confidence - STL Investment Paves the Way to Initial Public Offerings" />
        <meta property="og:title" content="Participate in IPOs with Confidence - STL Investment Paves the Way to Initial Public Offerings" />
        <meta property="twitter:title" content="Participate in IPOs with Confidence - STL Investment Paves the Way to Initial Public Offerings" />

        <meta name="description" content="Navigate the world of Initial Public Offerings (IPOs) effortlessly on STL Investment. Gain access to new investment opportunities, stay informed with market trends, and be part of groundbreaking financial ventures with our intuitive IPO platform." />
        <meta property="og:description" content="Navigate the world of Initial Public Offerings (IPOs) effortlessly on STL Investment. Gain access to new investment opportunities, stay informed with market trends, and be part of groundbreaking financial ventures with our intuitive IPO platform." />
        <meta property="twitter:description" content="Navigate the world of Initial Public Offerings (IPOs) effortlessly on STL Investment. Gain access to new investment opportunities, stay informed with market trends, and be part of groundbreaking financial ventures with our intuitive IPO platform." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <What />
        <Cards />
        <Why />
        <Priority />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default IpoPage;
