import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/PromotionsPage/Banner/Banner";
import GetBonus from "../components/PromotionsPage/GetBonus/GetBonus";
import Table from "../components/PromotionsPage/Table/Table";
import HowWork from "../components/PromotionsPage/HowWork/HowWork";
import Faq from "../components/PromotionsPage/Faq/Faq";
import Award from "../components/PromotionsPage/Award/Award";

const PromotionsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>STL Investment · Promotions</title>
        <meta name="title" content="Unlock Exclusive Promotions - Elevate Your Trading Experience with STL Investment" />
        <meta property="og:title" content="Unlock Exclusive Promotions - Elevate Your Trading Experience with STL Investment" />
        <meta property="twitter:title" content="Unlock Exclusive Promotions - Elevate Your Trading Experience with STL Investment" />

        <meta name="description" content="Maximize your trading potential with STL Investment's exclusive promotions. From bonuses to special offers, seize the opportunities that boost your portfolio. Join us and enjoy rewards that amplify your trading journey." />
        <meta property="og:description" content="Maximize your trading potential with STL Investment's exclusive promotions. From bonuses to special offers, seize the opportunities that boost your portfolio. Join us and enjoy rewards that amplify your trading journey." />
        <meta property="twitter:description" content="Maximize your trading potential with STL Investment's exclusive promotions. From bonuses to special offers, seize the opportunities that boost your portfolio. Join us and enjoy rewards that amplify your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <GetBonus />
        <Table />
        <HowWork />
        <Faq />
        <Award />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PromotionsPage;
