import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Tabs from "../components/AboutPage/Tabs/Tabs";
import Methods from "../components/AboutPage/Methods/Methods";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>STL Investment · About Us</title>
        <meta name="title" content="Get to Know STL Investment - Revolutionizing Your Trading Experience" />
        <meta property="og:title" content="Get to Know STL Investment - Revolutionizing Your Trading Experience" />
        <meta property="twitter:title" content="Get to Know STL Investment - Revolutionizing Your Trading Experience" />

        <meta name="description" content="Learn about the vision, values, and commitment of STL Investment. Discover how we're reshaping the future of trading with cutting-edge technology, transparent practices, and a customer-centric approach. Trust us to be your partner in financial success." />
        <meta property="og:description" content="Learn about the vision, values, and commitment of STL Investment. Discover how we're reshaping the future of trading with cutting-edge technology, transparent practices, and a customer-centric approach. Trust us to be your partner in financial success." />
        <meta property="twitter:description" content="Learn about the vision, values, and commitment of STL Investment. Discover how we're reshaping the future of trading with cutting-edge technology, transparent practices, and a customer-centric approach. Trust us to be your partner in financial success." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Tabs />
        <Methods />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
