import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import Underbanner from "../components/HomePage/Underbanner/Underbanner";
import Invest from "../components/HomePage/Invest/Invest";
import Cards from "../components/HomePage/Cards/Cards";
import Trader from "../components/HomePage/Trader/Trader";
import ShouldKnow from "../components/HomePage/ShouldKnow/ShouldKnow";
import Choose from "../components/HomePage/Choose/Choose";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>STL Investment</title>
        <meta name="title" content="Welcome to STL Investment - Your Gateway to Seamless Trading Experiences" />
        <meta property="og:title" content="Welcome to STL Investment - Your Gateway to Seamless Trading Experiences" />
        <meta property="twitter:title" content="Welcome to STL Investment - Your Gateway to Seamless Trading Experiences" />

        <meta name="description" content="Explore a world of possibilities with STL Investment, your premier trading platform. Discover real-time market updates, user-friendly interfaces, and expert insights for a truly immersive trading journey." />
        <meta property="og:description" content="Explore a world of possibilities with STL Investment, your premier trading platform. Discover real-time market updates, user-friendly interfaces, and expert insights for a truly immersive trading journey." />
        <meta property="twitter:description" content="Explore a world of possibilities with STL Investment, your premier trading platform. Discover real-time market updates, user-friendly interfaces, and expert insights for a truly immersive trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Underbanner />
        <Invest />
        <Cards />
        <Trader />
        <ShouldKnow />
        <Choose />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
